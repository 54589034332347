<template>
  <div class="form bg-app vh-100">
    <v-container fluid class="px-0">
      <v-alert
        v-if="!$online"
        dense
        tile
        color="gray"
        icon="mdi-alert-circle-outline"
        class="alert-offline text-headline"
      >
        {{ $t("t_no_connection") }}
      </v-alert>
      <v-row v-if="form">
        <v-col class="px-2">
          <v-card flat class="transparent">
            <div v-if="getHeaderImageTag(form.tags)" class="header-image">
              <v-img class="grid-header-img" :src="getHeaderImageTag(form.tags)" style="max-height: 240px"></v-img>
            </div>
            <v-toolbar flat class="transparent px-0 py-2 pt-1 bar-top" height="30">
              <v-toolbar-title>
                <v-btn
                  v-if="tab !== null && prevRoute && prevRoute.name == 'forms'"
                  :to="{ name: prevRoute.name, params: { tab: tab, type: type } }"
                  class="btn-secondary mx-0 mt-0"
                  small
                  ><v-icon>mdi-chevron-left</v-icon> {{ $t("c_formviewer.t_back_to_forms") }}</v-btn
                >
                <v-btn v-else @click="$router.go(-1)" class="btn-secondary mx-0 mt-0" small
                  ><v-icon>mdi-chevron-left</v-icon>{{ $t("t_back") }}</v-btn
                >
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <div class="status font-weight-bold pa-3 pl-4 pb-7 my-3 d-flex" v-if="form.submittedOn">
              <div>
                <span class="text-button orange--text text-darken-2">{{ $t("v_form.t_submitted") }}</span
                ><br /><span>{{ formatDateTime(form.submittedOn) }}</span>
                <div v-if="form.reviewedOn">
                  <span class="text-button green--text text-darken-1">{{ $t("v_form.t_reviewed") }}</span
                  ><br />{{ formatDateTime(form.reviewedOn) }}
                  {{ $t("v_form.t_by") }}
                  {{ form.reviewedByFriendlyName }}
                </div>
              </div>
            </div>
            <div class="form-wrap" id="formwrapper">
              <div class="form-title">
                <v-card-title class="form-title-name text-h5 px-4 pt-4">{{ form.name }}</v-card-title>
                <v-card-subtitle class="text-subtitle-1 px-4" v-if="form.description">{{
                  form.description
                }}</v-card-subtitle>
              </div>
              <v-form ref="sectionForm" v-model="formValid" lazy-validation>
                <form-viewer
                  :schema="schema"
                  :isAssignment="
                    Boolean(assignmentId)
                      ? Boolean(assignmentId)
                      : Boolean(submissionId)
                      ? Boolean(submissionId)
                      : false
                  "
                  ref="form"
                  :submission="Boolean(form.submittedOn)"
                  :form="form"
                  :formId="formId"
                  :submissionId="submissionId"
                  @save-form="saveForm"
                  @save-section="saveSection"
                  @validate="validate"
                />
              </v-form>
            </div>
            <v-card-actions v-if="formsVersion < 2" class="d-flex py-5 px-5 pt-5">
              <v-btn small v-if="$route.params.tab" :to="{ name: prevRoute.name, params: { tab: tab, type: type } }">{{
                $t("t_cancel")
              }}</v-btn>
              <v-btn small v-else-if="!$vuetify.breakpoint.xsOnly" @click="$router.go(-1)">{{ $t("t_cancel") }}</v-btn>
              <v-btn
                v-if="submissionId && !form.reviewedOn && form.startedOn && !form.submittedOn"
                small
                @click="deleteSubmissionConfirm(submissionId)"
                >{{ $t("t_delete") }}</v-btn
              >
              <v-spacer></v-spacer>
              <div v-if="form.reviewedOn" class="green--text text-darken-1">
                <v-icon small color="green darken-1" class="mr-2"> mdi-eye </v-icon>
                <span class="mt-1">{{ $t("v_form.t_reviewed") }}</span>
              </div>
              <div v-else-if="form.submittedOn" class="orange--text text-darken-2">
                {{ $t("v_form.t_submitted_for_review") }}
              </div>

              <div>
                <v-btn
                  v-if="form.assignedOn || form.formAssignmentId"
                  color="primary"
                  class="ml-2 mr-2"
                  @click="saveForm(false)"
                  >{{ $t("t_save") }}</v-btn
                >
                <v-btn color="primary" @click="saveForm(true)">{{
                  form.assignedOn || form.formAssignmentId ? $t("t_save_and_submit") : $t("t_submit")
                }}</v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <h4 class="pa-4" v-else-if="this.loaded">{{ $t("v_form.t_no_form") }}</h4>
    </v-container>
    <AppConfirmDialog ref="confirm" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Helpers from "@/mixins/helpers";
import FormViewer from "@/components/forms/FormViewer.vue";
import AppConfirmDialog from "@/components/AppConfirmDialog.vue";
import { AssetService, FormService } from "@/services";

export default {
  name: "Form",
  metaInfo: {
    title: "Form",
  },
  components: {
    FormViewer,
    AppConfirmDialog,
  },
  mixins: [Helpers],
  props: {
    tab: {
      type: Number,
    },
    type: {
      type: String,
      default: "assignments",
    },
  },
  computed: {
    ...mapGetters("user", ["user"]),
    ...mapActions("loader", ["pending", "done"]),
  },
  data: () => ({
    formsVersion: 2,
    loaded: false,
    form: null,
    formId: null,
    assignmentId: null,
    submissionId: null,
    schema: [],
    prevRoute: null,
    formValid: true,
    tempFiles: [],
    initFiles: [],
  }),
  methods: {
    ...mapActions("forms", [
      "getFormOpen",
      "getFormAssignment",
      "getFormSubmission",
      "saveFormSubmission",
      "updateFormSubmission",
      "deleteFormSubmission",
    ]),
    validate(submit, isAssignment, activeSection) {
      const dirtyInputs = this.schema.filter(
        (s) =>
          (s.answer?.required &&
            (activeSection == s.section || !s.section) &&
            (s.answer?.value === null ||
              s.answer?.value === "" ||
              (s.answer.ui.el == "checkbox" && !s.answer.value.some((v) => v == true)))) ||
          (s.ui.el == "signature" && (activeSection == s.section || !s.section) && s.required && s.value == "") ||
          (s.ui.el == "file" &&
            (activeSection == s.section || !s.section) &&
            s.required &&
            (s.file == null || s.file == "") &&
            s.value == null),
      );

      dirtyInputs.forEach((input) => {
        this.$refs.form.$refs[input.key][0].querySelector(".entry-section").classList.add("show-required");
      });

      if (!dirtyInputs || (dirtyInputs && !dirtyInputs.length)) {
        this.$refs.form.$refs["alert-check-required"].$el.classList.remove("show-alert");
        if (submit === false) {
          this.saveForm(false);
        } else if (submit === true) {
          this.saveForm(true);
        } else {
          if (!isAssignment) {
            let s = activeSection + 1;
            this.$refs.form.activeSection = s;
          } else {
            this.saveSection(activeSection);
          }
        }
      } else {
        this.$refs.form.$refs["alert-check-required"].$el.classList.add("show-alert");
      }
    },
    saveForm(doSubmit) {
      this.saveAnswers(doSubmit);
    },
    saveSection(activeSection) {
      this.saveAnswers(false, activeSection);
    },
    saveInputs: function (answers) {
      let resp = [];
      Object.keys(answers).forEach((key) => {
        if (key != "files") {
          let params = {};
          params.code = key;
          params.userResponse = Array.isArray(answers[key]) ? JSON.stringify(answers[key]) : answers[key];
          resp.push(params);
        }
      });
      return resp;
    },
    saveAnswers: async function (submit, step = null) {
      let answers = this.$refs.form.collectAnswers();
      let fileCount = 0;
      this.showLoadBar();
      Object.keys(answers).forEach(async (key) => {
        if (key == "files") {
          fileCount = answers[key].length;
          const promises = [];
          answers[key].forEach(async (item) => {
            if (item.value) {
              promises.push(this.fileUpload(item.key, item.value));
            } else if (this.schema.find((f) => f.key == item.key)) {
              let existingUrl = new Promise((resolve) => {
                let file = this.schema.find((f) => f.key == item.key).file;
                let params = {};
                params.code = item.key;
                params.userResponse = file;
                resolve(params);
              });
              promises.push(existingUrl);
            }
          });
          Promise.all(promises).then((r) => {
            let resp = r.flat();
            let inputs = this.saveInputs(answers);
            inputs.forEach((r) => {
              resp.push(r);
            });
            this.doSaveAnswers(submit, resp, step);
          });
        }
      });
      if (fileCount == 0) {
        let resp = this.saveInputs(answers);
        this.doSaveAnswers(submit, resp, step);
      }
    },
    async doSaveAnswers(doSubmit, responses, step = null) {
      let result = await this.submitFormSubmission(false, responses);
      if (this.submissionId && this.tempFiles.length > 0) {
        //No API PUT support for updating assets, so we set and check the initial files and delete if necessary
        //the API has validation against changes to submissions, but assets require a submissionId - therefore we queue the assets to get the submissionId and submit the submission after save if submitted.
        this.tempFiles.forEach(async (f) => {
          if (this.initFiles.some((i) => i.key == f.key)) {
            await FormService.deleteFormSubmissionAsset({
              formSubmissionId: this.submissionId,
              key: f.key,
            });
          }
          await FormService.createFormSubmissionAsset({
            formSubmissionId: this.submissionId,
            assetId: f.assetId,
            key: f.key,
          });
        });
      }

      this.tempFiles = [];
      this.initFiles = [];

      if (doSubmit) {
        result = await this.submitFormSubmission(doSubmit, responses);
      }
      if (result) {
        this.saveFormHandler(doSubmit, step);
      }
    },

    async submitFormSubmission(doSubmit, responses) {
      let payload = {};
      payload.submit = doSubmit;
      payload.fields = responses;
      let result = null;
      if (this.submissionId) {
        payload.id = this.submissionId;
        result = await this.updateFormSubmission(payload);
      } else if (this.assignmentId) {
        payload.revisionId = this.form.formRevisionId ? this.form.formRevisionId : this.form.latestRevisionId;
        payload.assignmentId = this.assignmentId;
        result = await this.saveFormSubmission(payload);
        this.submissionId = result.data.submissionId;
      } else if (this.formId) {
        payload.revisionId = this.form.formRevisionId ? this.form.formRevisionId : this.form.latestRevisionId;
        payload.id = this.formId;
        result = await this.saveFormSubmission(payload);
        this.submissionId = result.data.submissionId;
      }
      return result;
    },

    saveFormHandler(doSubmit, step) {
      this.hideLoadBar();
      if (step || step === 0) {
        let s = step + 1;
        this.$refs.form.activeSection = s;
      } else {
        this.$router.push({
          name: this.prevRoute?.name ? this.prevRoute.name : "forms",
          params: { tab: this.$route.params.tab },
        });

        this.$snackbar.showMessage({
          content: doSubmit
            ? this.$t("c_snackbar.t_success_form_submitted")
            : this.$t("c_snackbar.t_success_form_saved"),
          color: "success",
          timeout: "",
        });
      }
    },

    fileUpload: async function (key, file) {
      return new Promise((resolve) => {
        return this.imageUpload(file, key).then((r) => {
          let params = {};
          params.submissionId = this.submissionId;
          params.code = r.code;
          params.userResponse = r.key;
          resolve(params);
        });
      });
    },

    imageUpload: async function (file, fieldId) {
      const payload = {
        name: file.name,
        description: "",
        originalFileName: file.name,
        mimeType: file.type,
        cacheable: true,
        permitCompany: true,
      };
      const result = await AssetService.uploadAssetBegin(payload);
      const assetId = result.data.assetId;
      const uploadUrl = result.data.uploadUrl;
      const key = result.data.assetGuid;
      if (assetId && uploadUrl && key) {
        const uploadS3Asset = async (uploadUrl, data) => {
          return fetch(uploadUrl, {
            method: "PUT",
            body: data,
            headers: {
              "Content-Type": file.type,
            },
          });
        };
        const r = await uploadS3Asset(uploadUrl, file);
        if (r.ok) {
          await AssetService.uploadAssetComplete({ assetId: assetId });
          //save to model to save assets to submission
          this.tempFiles.push({
            assetId: assetId,
            key: fieldId,
          });
          return { code: fieldId, key: key };
        } else if (this.submissionId) {
          await FormService.deleteFormSubmissionAsset({
            formSubmissionId: this.submissionId,
            key: fieldId,
          });
        }
      }
    },

    async deleteSubmissionConfirm(submissionId) {
      if (await this.$refs.confirm.open(this.$t("c_confirm.t_confirm_delete"))) {
        this.deleteSubmission(submissionId);
      }
    },
    deleteSubmission(submissionId) {
      this.deleteFormSubmission(submissionId).then(() => {
        this.responseMessage("c_snackbar.success_form_deleted", 2000);
        this.$router.push({
          name: "forms",
          params: { tab: this.$route.params.tab },
        });
      });
    },
    getPendingSubmission(submissionId) {
      if (!submissionId) {
        this.init();
        return;
      }
      this.submissionId = submissionId;
      let params = {};
      params.id = submissionId;
      this.getFormSubmission(params).then((fs) => {
        let input = fs.data;
        this.schema.forEach((item) => {
          let field = input.find((f) => f.code == item.key);
          if (field) {
            if (item.answer?.ui.el == "radio" || item.answer?.ui.el == "checkbox") {
              item.answer.value = JSON.parse(field.userResponse);
            } else if (item.ui.el == "file") {
              item.value = field.userResponse;
            } else {
              item.answer.value = field.userResponse;
            }
          }
        });
      });
    },
    getHeaderImageTag(tags) {
      //temporary hack until we can get a header prop on forms...
      const headerTag = tags ? tags.split(",")[1] : "";
      if (headerTag) {
        return (
          "https://clockworksafety-public.s3.us-west-2.amazonaws.com/form-headers/" + headerTag.split("header-")[1]
        );
      }
      return "";
    },
    init() {
      let params = this.$route.params;
      let type = this.$route.name;
      this.prevTab = this.$route.params?.tab;
      if (type == "assignment") {
        this.assignmentId = params.id;
      } else if (type == "form") {
        this.formId = Number(params.id);
      } else if (type == "submission") {
        this.submissionId = params.id;
      }
      this.loaded = false;
      if (this.submissionId) {
        this.getFormSubmission({ id: this.submissionId }).then((r) => {
          this.form = r.data;
          this.formId = this.form.formId;
          this.submissionId = this.form.id;
          this.schema = [...JSON.parse(this.form.serializedFormContent)];
          //inputs:
          this.schema
            .filter((s) => s.answer)
            .forEach((s) => {
              let answer = this.form.fields.find((f) => f.formFieldCode == s.key)?.userResponse;
              if (s.answer.ui.el === "checkbox" || s.answer.ui.el === "radio") {
                answer = JSON.parse(answer);
              }
              s.answer.value = answer;
            });
          //signatures
          this.schema
            .filter((s) => s.ui.el == "signature")
            .forEach((s) => {
              s.value = this.form.fields.find((f) => f.formFieldCode == s.key)?.userResponse;
            });
          //files:
          this.schema
            .filter((s) => s.ui.el == "file")
            .forEach((s) => {
              s.file = this.form.fields.find((f) => f.formFieldCode == s.key)?.userResponse;
            });
          //check against existing files to manage updates
          this.initFiles = this.schema.filter((s) => s.file && s.file != null);
          this.loaded = true;
        });
      } else if (this.assignmentId) {
        this.getFormAssignment(this.assignmentId).then((r) => {
          this.form = r.data;
          this.formId = this.form.formId;
          this.assignmentId = this.form.id;
          this.schema = [...JSON.parse(this.form.serializedFormContent)];
          this.loaded = true;
        });
      } else if (this.formId) {
        this.getFormOpen(this.formId).then((r) => {
          this.form = r.data;
          this.schema = [...JSON.parse(this.form.serializedFormContent)];
          this.loaded = true;
        });
      }
    },
  },
  mounted() {
    if (this.$online) {
      this.init();
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = vm ? from : "forms";
    });
  },
};
</script>
<style lang="scss">
.form {
  .theme--dark {
    color: #eee !important;
    &.error--text {
      color: #fa4646 !important;
      caret-color: #b71c1c !important;
    }
  }
  .container {
    max-width: 100%;
  }
  .form-wrap {
    padding-bottom: 15px;
  }
  .header-image {
    margin: -7px -9px 8px;
  }
  .status {
    border-bottom: 3px solid #808080;
  }
  .form-title-name {
    word-break: keep-all;
  }
}
</style>
